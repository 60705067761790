.container {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.border {
  border-bottom: 1px solid grey;
  width: 80%;
}

.short-border {
  border-bottom: 1px solid grey;
  width: 60px;
}

.content {
  margin: 0 10px;
  width: inherit;
}
