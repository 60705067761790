@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@selected-background: #f0566390;

// select temporary area
.selected:not(.highlight) {
  fill: @selected-background;
  opacity: 0.3 !important;
  stroke: red;
  stroke-width: 4;
  stroke-linecap: butt;
  stroke-dasharray: 0;
}

.highlight.selected {
  stroke: red;
  stroke-width: 6;
  stroke-linecap: butt;
  stroke-dasharray: 0;
}


.no-gap-form {
  .ant-form-item {
    margin-bottom: 0;
    padding: 0;
  }
}

// side bar menu
.extraction-menu {
  .ant-menu-item {
    margin: 0 !important;
  }
}

// Tabs nav bar
.ant-tabs-nav-wrap {
  justify-content: center;
}

// message
.ant-message-custom-content {
  display: flex;
  align-items: center;
}


.tooltip-table {

  th, td {
    padding: 3px 6px;
    border: 1px solid grey;
  }

  td {
    font-weight: 600;
  }
}

.sidebar-dragger {
  width: 2.5px;
  cursor: ew-resize;
  padding: 4px 0 0;
  border-top: 1px solid grey;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: grey;
}


// carousel
.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  left: 10px;
  z-index: 2;
  color: black;
  font-size: 20px;
  height: 30px;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  right: 10px;
  z-index: 2;
  color: black;
  font-size: 20px;
  height: 30px;
}
